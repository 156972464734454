import React, {useContext, useState} from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import "./UploadTemplate.css";
import {GlobalStateContext} from "../Context/GlobalStateContext";

Modal.setAppElement('#root');

const UploadTemplate = () => {
    const {serverUrl} = useContext(GlobalStateContext);
    const [file, setFile] = useState(null);
    const [templateName, setTemplateName] = useState('');
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const onChangeFile = e => {
        setFile(e.target.files[0]);
    };

    const onChangeTemplateName = e => {
        setTemplateName(e.target.value);
    };

    const onSubmit = async e => {
        e.preventDefault();
        if (!file || !templateName) {
            setMessage('Please select a file and enter a template name.');
            return;
        }

        const formData = new FormData();
        formData.append('templateName', templateName);
        formData.append('file', file);

        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        try {
            const res = await axios.post(serverUrl + '/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });
            setMessage('Template uploaded successfully');
        } catch (err) {
            if (err.response) {
                setMessage(err.response.data.msg || 'Error uploading template');
            } else {
                setMessage('There was a problem with the server');
            }
        }
    };

    return (
        <div>
            <button onClick={() => setModalIsOpen(true)} className="template-upload-button">
                <FontAwesomeIcon icon={faPlus}/> Add
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    }
                }}
            >
                <h2>Upload JSON Template</h2>
                {message && <p>{message}</p>}
                <form onSubmit={onSubmit}>
                    <div className="template-upload-name">
                        <label>Template Name:</label>
                        <input type="text" value={templateName} onChange={onChangeTemplateName} required/>
                    </div>
                    <div>
                        <input type="file" onChange={onChangeFile} accept=".json" required/>
                    </div>
                    <div className="template-upload-footer">
                        <button onClick={() => setModalIsOpen(false)}>Close</button>
                        <button type="submit">Upload</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default UploadTemplate;
