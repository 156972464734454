import React, {createContext, useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({children}) => {
    const {user, login, serverURL} = useContext(AuthContext);
    const [serverUrl] = useState(serverURL);
    const [ferrymanVersion] = useState("v1.6.0 demo clock");
    const [theme, setTheme] = useState('dark');
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        console.log('%c  StreamShapers Ferryman  ', 'border-radius: 5px; font-size: 1.1em; padding: 10px; background: #4ba1e2; color: #fff; font-family: OpenSans-Regular, arial;');
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await fetch(serverUrl + '/users', {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setUserInfo(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Error fetching user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [navigate, user]);

    return (
        <GlobalStateContext.Provider value={{
            ferrymanVersion,
            error,
            setError,
            theme,
            setTheme,
            userInfo,
            loading,
            serverUrl
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
}