import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AuthContext from './Context/AuthContext';

const Login = () => {
    const [formData, setFormData] = useState({
        loginMail: '',
        loginPassword: ''
    });
    const navigate = useNavigate();
    const {login} = useContext(AuthContext);

    const {loginMail, loginPassword} = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        try {
            console.log('Sending Login-Data...');
            await login(formData);
            console.log('Login successful, directing to Dashboard-Site...');
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error.message);
        }
    };

    return (
        <div className="auth-wrapper">
            <div className="auth-dialog">
                <form onSubmit={onSubmit}>
                    <h2>Login</h2>
                    <div className="auth-input">
                        <input autoComplete="email" type="email" name="loginMail" value={loginMail} onChange={onChange}
                               required/>
                        <label className="input-label">E-Mail:</label>
                    </div>
                    <div className="auth-input">

                        <input autoComplete="current-password" type="password" name="loginPassword" value={loginPassword}
                               onChange={onChange} required/>
                        <label className="input-label">Password:</label>
                    </div>
                    <button className="auth-button" type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
