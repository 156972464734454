import React, {useContext} from 'react';
import {GlobalStateContext} from "../Context/GlobalStateContext";
import UserSettings from "./UserSettings";
import AuthContext from "../Context/AuthContext";
import {useNavigate} from "react-router-dom";

const Settings = () => {
    const {error} = useContext(GlobalStateContext);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!user) {
        navigate('/login');
        return;
    }

    return (
        <div className="container">
            <h1>Settings</h1>
            <UserSettings/>
        </div>
    );
};

export default Settings;
