import React, { useContext } from 'react';
import {GlobalStateContext} from "../Context/GlobalStateContext";

const UserSettings = () => {
    const {userInfo, loading, error} = useContext(GlobalStateContext);

    return (
        <div className="container">
            <h1>Dashboard</h1>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {loading ? (
                <p>Loading user data... <span className="spinner"></span></p>
            ) : (
                userInfo ? (
                    <div>
                        <h2>Welcome, {userInfo.username}!</h2>
                    </div>
                ) : (
                    <p>No user data available.</p>
                )
            )}
        </div>
    );
};

export default UserSettings;
