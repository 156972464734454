import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faRightToBracket, faUser} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useState, useRef, useEffect } from "react";
import ThemeSwitch from "./Theme/ThemeSwitch";
import AuthContext from "./Context/AuthContext";
import { GlobalStateContext } from "./Context/GlobalStateContext";
import { Link } from 'react-router-dom';

function Header() {
    const { user, logout } = useContext(AuthContext);
    const { theme } = useContext(GlobalStateContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            <div id="headerContainer">
                <div className="headerSide"></div>
                <div id="header-title">
                    <a href="https://www.streamshapers.com/"><img id="logo-img" src={theme === 'dark' ? './logo-light.png' : './logo-dark.png'} alt="logo" /></a>
                    <h1>Hosting</h1>
                </div>
                <div className="headerSide">
                    <ThemeSwitch />
                    <div id="account-info-button" className="headerButton headerButton1" onClick={toggleDropdown} ref={dropdownRef}>
                        <FontAwesomeIcon icon={faUser} title="Account" />
                        {user && <span>{user.username}</span>}
                        {dropdownOpen && (
                            <div className="dropdown-content open">
                                <Link to="/settings" className="dropdown-item">Settings</Link>
                                <div className="dropdown-item logout" onClick={logout}>
                                    Logout
                                    <FontAwesomeIcon icon={faRightToBracket} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
