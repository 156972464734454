import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import AuthContext from "./Context/AuthContext"; // Importiere Link von react-router-dom

const Home = () => {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();

    if (user) {
        navigate('/dashboard');
        return;
    }
    return (
        <>
            {!user && (
                <div className="auth-wrapper">
                    <div className="auth-dialog">
                        <h1>StreamShapers Hosting</h1>
                        <p>Login or create new Account</p>
                        <div className="auth-buttons">
                            <Link to="/register">
                                <button className="auth-button">
                                    Register
                                </button>
                            </Link>
                            <Link to="/login">
                                <button className="auth-button">
                                    Login
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Home;