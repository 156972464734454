import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AuthContext from '../Context/AuthContext';
import { Player } from '@lottiefiles/react-lottie-player';
import './TemplateGrid.css';
import UploadTemplate from "./UploadTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faTrash} from "@fortawesome/free-solid-svg-icons";
import {GlobalStateContext} from "../Context/GlobalStateContext";

const TemplateGrid = () => {
    const { user } = useContext(AuthContext);
    const {serverUrl} = useContext(GlobalStateContext);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [hoveredTemplateId, setHoveredTemplateId] = useState(null);
    const [showDropdown, setShowDropdown] = useState(null);
    const playerRefs = useRef({});
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true);
            try {
                const res = await axios.get(serverUrl + '/templates', {
                    withCredentials: true
                });
                setTemplates(res.data);
            } catch (err) {
                console.error('Fehler beim Abrufen der Templates:', err);
                setError('Fehler beim Abrufen der Templates');
            } finally {
                setLoading(false);
            }
        };

        fetchTemplates();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseEnter = (templateId) => {
        setHoveredTemplateId(templateId);
        if (playerRefs.current[templateId]) {
            playerRefs.current[templateId].play();
        }
    };

    const handleMouseLeave = (templateId) => {
        setHoveredTemplateId(null);
        if (playerRefs.current[templateId]) {
            playerRefs.current[templateId].pause();
        }
    };

    const handleDropdownToggle = (templateId) => {
        setShowDropdown(showDropdown === templateId ? null : templateId);
    };

    const handleEdit = (templateId) => {
        console.log(`Bearbeiten Template: ${templateId}`);
        // Bearbeiten Funktionalität hier hinzufügen
    };

    const handleDelete = async (templateId) => {
        try {
            const res = await axios.delete(serverUrl + `/templates/${templateId}`, {
                withCredentials: true
            });
            console.log(res.data.msg);
            setTemplates(templates.filter(template => template._id !== templateId));
        } catch (err) {
            console.error('Fehler beim Löschen des Templates:', err);
        }
    };

    return (
        <div className="template-grid-component">
            <div className="template-grid-header">
                <div></div>
                <div>
                    <UploadTemplate />
                </div>
            </div>
            <div className="template-grid">
                {loading ? (
                    <p>Loading Templates...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    templates.map(template => (
                        <div
                            className="template-card"
                            key={template._id}
                            onMouseEnter={() => handleMouseEnter(template._id)}
                            onMouseLeave={() => handleMouseLeave(template._id)}
                        >
                            <div className="template-card-header">
                                <div></div>
                                <h3>{template.name}</h3>
                                <FontAwesomeIcon className="template-card-more" icon={faEllipsisVertical} onClick={() => handleDropdownToggle(template._id)} />
                                {showDropdown === template._id && (
                                    <div className="template-card-dropdown-menu" ref={dropdownRef}>
                                        <button onClick={() => handleEdit(template._id)}>Edit</button>
                                        <button className="delete-button" onClick={() => handleDelete(template._id)}>
                                            <FontAwesomeIcon icon={faTrash} /> Delete
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="template-card-body">
                                <Player
                                    ref={el => playerRefs.current[template._id] = el}
                                    src={`${serverUrl}/${template.path}`} // Pfad zur Lottie JSON-Datei
                                    style={{ height: 'auto', width: '100%' }}
                                    loop
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default TemplateGrid;
